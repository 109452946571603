import React, { useState } from 'react';
import confetti from 'canvas-confetti';
import Header from './Header';
import Footer from './Footer';
import { moodEntry } from './moodSubmission';
import { useNavigate } from 'react-router-dom';


const JournalEntryForm = () => {
  const [mood, setMood] = useState('');
  const [moodRating, setMoodRating] = useState(10);
  const [entry, setEntry] = useState('');
  const navigate = useNavigate();

  const handleComplete = () => {
    confetti({
      particleCount: 500,
      spread: 70,
      origin: { y: 0.6 },
    });

try {
      moodEntry(moodRating, entry); // ✅ Ensure this is an async function
      navigate('/dashboard'); // ✅ Redirect to dashboard on success
    } catch (error) {
      console.error("Failed to add entry:", error);
    }  };

  const handleClear = () => {
    setMood('');
    setMoodRating(5);
    setEntry('');
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-blue-50 to-purple-50">
      <Header />
      <div className="flex flex-col items-center justify-center flex-grow p-4">
        <div className="w-full max-w-2xl bg-white rounded-lg shadow-lg p-6 border border-gray-100">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">How are you feeling today?</h2>

          {/* Mood Icons */}
          <div className="flex justify-around mb-6">
            {['😊', '😐', '😢', '😡', '😴'].map((emoji, index) => (
              <button
                key={index}
                className={`text-4xl p-2 rounded-full transition-colors ${
                  mood === emoji ? 'bg-indigo-100' : 'hover:bg-gray-100'
                }`}
                onClick={() => setMood(emoji)}
              >
                {emoji}
              </button>
            ))}
          </div>

          {/* Mood Scale */}
          <div className="mb-6">
            <label htmlFor="moodRating" className="block text-sm font-medium text-gray-700 mb-2">
              Mood Rating: {moodRating}
            </label>
            <input
              type="range"
              id="moodRating"
              min="1"
              max="10"
              value={moodRating}
              onChange={(e) => setMoodRating(e.target.value)}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
            />
          </div>

          {/* Text Area */}
          <textarea
            id="MoodEntry"
            className="w-full resize-none border-none align-top focus:ring-0 sm:text-sm placeholder-gray-400 mb-4"
            rows={8}
            placeholder="What is on your mind? Write a few words..."
            value={entry}
            onChange={(e) => setEntry(e.target.value)}
          ></textarea>

          {/* Prompt Suggestions */}
          <div className="text-sm text-gray-500 mb-4">
            <p>Need inspiration? Try:</p>
            <ul className="list-disc list-inside">
              <li>What made you smile today?</li>
              <li>What challenges did you face?</li>
              <li>What are you grateful for?</li>
            </ul>
          </div>

          {/* Buttons */}
          <div className="flex items-center justify-end gap-2">
            <button
              type="button"
              className="rounded-md bg-gray-100 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-200 transition-colors"
              onClick={handleClear}
            >
              Clear
            </button>

            <button
              type="button"
              className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 transition-colors"
              onClick={handleComplete}
            >
              Add Entry
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default JournalEntryForm;