import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignupForm from './SignupForm';
import SigninForm from './SigninForm';
import JournalEntryForm from './JournalEntryForm';
import ComingSoon from './ComingSoon';
import EmailVerificationPage from './Emailverification';

// eslint-disable-next-line
function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/signup" element={<SignupForm />} />
          <Route path="/" element={<SigninForm />} />
          <Route path='/mood_entry' element={<JournalEntryForm />} />
          <Route path="/dashboard" element={<ComingSoon />} />
          <Route path="/verify-email" element={<EmailVerificationPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;