const host = "https://mood-brew-backend.onrender.com"

export async function moodEntry(rating, desc) {
  const response = await fetch(host + '/api/journals/submit-entry', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ 
        "rating": rating,
        "desc": desc
     }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Entry failed to be logged');
  }

  return response.json();
}