import React, { useState } from 'react';
import { signin } from './auth'; // Import the signup function
import * as constants from './constants';
import { useNavigate } from 'react-router-dom';
import writingPersonIcon from "./assets/icon/writing.png";

export default function SigninForm() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);

        console.log("executing....")
        console.log(email, password)
        console.log("var logging complete")

        try {
            const data = await signin(email, password);
            console.log('Signin successful:', data);
            // Redirect or show success message
            navigate('/mood_entry');
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <section className="bg-white">
            <div className="lg:grid lg:min-h-screen lg:grid-cols-12">
                <aside className="relative block h-16 lg:order-last lg:col-span-5 lg:h-full xl:col-span-6">
                    <img
                        alt=""
                        src="https://images.unsplash.com/photo-1605106702734-205df224ecce?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
                        className="absolute inset-0 h-full w-full object-cover"
                    />
                </aside>

                <main
                    className="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12 xl:col-span-6"
                >
                    <div className="max-w-xl lg:max-w-3xl">
                        <a className="block text-blue-600" href="#">
                            <span className="sr-only">Home</span>

                        </a>

                        <h1 className="mt-6 text-2xl font-bold text-gray-900 sm:text-3xl md:text-4xl flex items-center">
                            Welcome to {constants.org_name}
                            <img
                                src={writingPersonIcon}
                                alt="logoIcon"
                                className="h-8 w-8 ml-2"
                            />
                        </h1>

                        <p className="mt-4 leading-relaxed text-gray-500">
                            {constants.sign_in_page_title} <br></br>
                            {constants.sign_in_page_subtitle}
                        </p>

                        <form action="#" className="mt-8 grid grid-cols-6 gap-6" onSubmit={handleSubmit}>
                            <div className="col-span-6">
                                <label htmlFor="Email" className="block text-sm font-medium text-gray-700"> Email </label>

                                <input
                                    type="email"
                                    id="Email"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-xs"
                                />
                            </div>

                            <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="Password" className="block text-sm font-medium text-gray-700"> Password </label>

                                <input
                                    type="password"
                                    id="Password"
                                    name="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-xs"
                                />
                            </div>

                            <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
                                <button
                                    className="inline-block shrink-0 rounded-md border border-blue-600 bg-blue-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-blue-600 focus:ring-3 focus:outline-hidden"
                                    type="submit"
                                    disabled={isLoading}
                                >
                                    {console.log("isLoading::: " + isLoading)}
                                    {isLoading ? "Signing in..." : "Signin"}
                                </button>
                                <p className="mt-2 text-sm text-gray-500 sm:mt-0">
                                    New here?
                                    <a
                                        href="/signup"
                                        className="ml-1 font-medium text-blue-600 hover:underline"
                                    >
                                        Sign up
                                    </a>
                                </p>
                            </div>
                        </form>
                    </div>
                </main>
            </div>
        </section>
    );
}