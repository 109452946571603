import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signout } from "./auth";
import writingPersonIcon from "./assets/icon/writing.png";
import { Menu, X } from "lucide-react"; // ✅ Icons for mobile menu

const Header = () => {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleLogout = async () => {
    await signout();
    navigate("/");
  };

  return (
    <header className="bg-white shadow-sm fixed top-0 left-0 w-full z-50">
      <div className="container mx-auto flex justify-between items-center p-4">
        {/* Logo */}
        <h1 className="text-xl sm:text-2xl flex items-center font-bold text-gray-800">
          CalmingCanvas
          <img src={writingPersonIcon} alt="logoIcon" className="h-8 w-8 ml-2" />
        </h1>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex space-x-6">
          <a href="/dashboard" className="text-gray-600 hover:text-indigo-600">
            Dashboard
          </a>
          <a href="/mood_entry" className="text-gray-600 hover:text-indigo-600">
            Make My Entry
          </a>
          <button
            onClick={handleLogout}
            className="text-gray-600 hover:text-indigo-600"
          >
            Logout
          </button>
        </nav>

        {/* Mobile Menu Button */}
        <button
          className="md:hidden p-2 rounded-lg hover:bg-gray-100"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden absolute top-16 left-0 w-full bg-white shadow-md p-4">
          <nav className="flex flex-col space-y-4 text-center">
            <a href="/dashboard" className="text-gray-700 hover:text-indigo-600">
              Dashboard
            </a>
            <a href="/mood_entry" className="text-gray-700 hover:text-indigo-600">
              Make My Entry
            </a>
            <button
              onClick={handleLogout}
              className="text-gray-700 hover:text-indigo-600"
            >
              Logout
            </button>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;
