import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function EmailVerificationPage() {
  const [status, setStatus] = useState("loading");
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const verificationStatus = params.get("status");

    if (verificationStatus === "success") {
      setStatus("success");
    } else {
      setStatus("failed");
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      {status === "loading" && <p className="text-lg">Verifying...</p>}
      {status === "success" && (
        <div className="text-center">
          <h1 className="text-2xl font-bold text-green-600">Email Verified!</h1>
          <p className="text-gray-700 mt-2">Your email has been successfully verified.</p>
          <button
            onClick={() => navigate("/")}
            className="mt-4 bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700"
          >
            Go to Sign In
          </button>
        </div>
      )}
      {status === "failed" && (
        <div className="text-center">
          <h1 className="text-2xl font-bold text-red-600">Verification Failed</h1>
          <p className="text-gray-700 mt-2">Invalid or expired token.</p>
        </div>
      )}
    </div>
  );
}
