import React from 'react';
import Countdown from 'react-countdown';
import Header from './Header';
import Footer from './Footer';

const ComingSoon = () => {
  // Set the target date for the countdown
  const targetDate = new Date('2025-03-31T00:00:00');

  // Renderer for the countdown timer
  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="flex justify-center space-x-4 mb-8">
        <div className="bg-indigo-100 p-4 rounded-lg">
          <span className="text-2xl font-bold text-indigo-600">{days}</span>
          <span className="block text-sm text-gray-600">Days</span>
        </div>
        <div className="bg-indigo-100 p-4 rounded-lg">
          <span className="text-2xl font-bold text-indigo-600">{hours}</span>
          <span className="block text-sm text-gray-600">Hours</span>
        </div>
        <div className="bg-indigo-100 p-4 rounded-lg">
          <span className="text-2xl font-bold text-indigo-600">{minutes}</span>
          <span className="block text-sm text-gray-600">Minutes</span>
        </div>
        <div className="bg-indigo-100 p-4 rounded-lg">
          <span className="text-2xl font-bold text-indigo-600">{seconds}</span>
          <span className="block text-sm text-gray-600">Seconds</span>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-blue-50 to-purple-50">
      <Header />
      <div className="flex flex-col items-center justify-center flex-grow p-4">
        <div className="w-full max-w-2xl bg-white rounded-lg shadow-lg p-6 border border-gray-100 text-center">
          <h1 className="text-4xl font-bold text-gray-800 mb-4">Coming Soon!</h1>
          <p className="text-lg text-gray-600 mb-6">
            Our dashboard is under construction. Stay tuned for something amazing!
          </p>

          {/* Countdown Timer */}
          <Countdown date={targetDate} renderer={renderer} />

          {/* Call-to-Action Button */}
          <button
            className="rounded-md bg-indigo-600 px-6 py-3 text-sm font-medium text-white hover:bg-indigo-700 transition-colors"
            onClick={() => alert('Notify me when it’s ready!')}
          >
            Notify Me
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ComingSoon;